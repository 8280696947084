import utility from '@/common/utility'
import auth from '@/common/auth'

export default class G14Model {
  constructor (props) {
    if (props) {
      this.resourceId = props.g14Guid
      this.g14Guid = props.g14Guid
      this.planStatExaminationGuid = props.planStatExaminationGuid
      this.engineeringListGuid = props.engineeringListGuid
      this.currentYearNum = props.currentYearNum
      this.totalYearNum = props.totalYearNum
      this.totalYearPercent = props.totalYearPercent
      this.totalNum = props.totalNum
      this.totalPercent = props.totalPercent
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.tenderGuid = props.tenderGuid
      this.year = props.year
      this.month = props.month
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.g14Guid) this.g14Guid = ''
    if (!this.planStatExaminationGuid) this.planStatExaminationGuid = ''
    if (!this.engineeringListGuid) this.engineeringListGuid = ''
    if (!this.currentYearNum) this.currentYearNum = 0
    if (!this.totalYearNum) this.totalYearNum = 0
    if (!this.totalYearPercent) this.totalYearPercent = 0
    if (!this.totalNum) this.totalNum = 0
    if (!this.totalPercent) this.totalPercent = 0
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.year) this.year = 0
    if (!this.month) this.month = 0
  }

  generatePrimaryKey () {
    this.g14Guid = utility.getUuid()
    this.resourceId = this.g14Guid
  }
}
